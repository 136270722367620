import { Helmet } from "react-helmet";
import Layout from "../../components/layout";

export default function Cavalcade() {
  return (
    <Layout>
      <Helmet>
        <title>Cavalcade | Nathan Pasko</title>
        <meta name="description" content="Cavalcade is an experimental first-person horror game. Download for Mac, Windows, or Linux to explore a creepy museum full of odd bodies made of skin lumps and physics." />
      </Helmet>
      <div className="project-page">
        <ul className="horizontal-scroll">
          <li>
            <picture>
              <img
                src="http://avknights.com/pic/cav/dulmo.png"
                alt=""
                height="1024"
                width="1030"
              />
            </picture>
          </li>
          <li>
            <picture>
              <img
                src="http://avknights.com/pic/cav/vrybe2.png"
                height="900"
                width="1029"
              />
            </picture>
          </li>
          <li>
            <picture>
              <img
                src="http://avknights.com/pic/cav/vrybe1.png"
                height="900"
                width="1030"
              />
            </picture>
          </li>
          <li>
            <picture>
              <img
                src="http://avknights.com/pic/cav/mulmasha.png"
                alt=""
                height="900"
                width="1030"
              />
            </picture>
          </li>
        </ul>
        <h1>Cavalcade</h1>
        <p>
          Cavalcade is a short experimental horror game. It was created for the{" "}
          <a href="https://itch.io/jam/the-haunted-ps1-halloween-jam">
            Haunted PS1 Halloween Jam
          </a>{" "}
          and imitates the look of the PlayStation. The player can explore a
          museum-like place and examine and interact with large lumpy bodies.
          Some of the creatures in Cavalcade have limbs and body parts that
          simulate physics so the player can poke at them and push them around.
        </p>
        <iframe frameborder="0" src="https://itch.io/embed/320878?border_width=0&amp;fg_color=000&amp;link_color=e0e0e0&amp;border_color=fff" width="206" height="165"><a href="https://avknights.itch.io/cavalcade">Cavalcade by A.V. Knights</a></iframe>
      </div>
    </Layout>
  );
}
